import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, TableCell} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IAdminUsersLessonsResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {useApi} from "@plumeuk/shapeshift-identity";
import {HeadCell} from "@plumeuk/shapeshift-common/admin/table/EnhancedTableHead";
import {AdminHeader, AdminTable} from "@plumeuk/shapeshift-common/admin";
import {GridColDef} from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((_theme) => ({
	adminLessonsList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells = (locales?: ILocalizationEntity[]): GridColDef<IAdminUsersLessonsResponseEntry>[] => ([
	{
		field: "title",
		flex: 1,
		headerName: "Title",
		width: 200
	},
	{
		field: "languages",
		flex: 1,
		headerName: "Languages",
		filterable: false,
		valueGetter: ({value}: {value: IAdminUsersLessonsResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	},
	{
		field: "createdAt",
		flex: 1,
		headerName: "Created",
		filterable: true,
		width: 100,
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "publishedAt",
		flex: 1,
		headerName: "Published",
		width: 100,
		filterable: true,
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	}
]);

export const AdminLessonsList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const locales:{data: ILocalizationEntity[]} = {data: []}; //> pending conversation on locale support

	return (
		<Box className={classes.adminLessonsList}>
			<AdminHeader
				title="Lessons"
				subtitle="Manage lessons"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"lesson", IAdminUsersLessonsResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/lesson"
				columns={headCells(locales.data)}
				enableDelete
				type="lesson"
				onEditAction={e => navigate(e.id.toString())}
			/>
		</Box>
	);
}