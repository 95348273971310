import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {CourseLibraryPage} from "./pages/CourseLibrary/CourseLibraryPage";
import {ForgottenPasswordPage} from "./pages/ForgottenPassword/ForgottenPassword";
import {ResetPasswordPage} from "./pages/ResetPassword/ResetPassword";
import {CourseLibraryProductPage} from "./pages/CourseLibraryProduct/CourseLibraryProductPage";
import {CoursePage} from "./pages/CoursePage/CoursePage";
import {LoginPage} from "./pages/LoginPage/LoginPage";
import {RegisterPage} from "./pages/RegisterPage/RegisterPage";
import {MyLearningPage} from "./pages/MyLearningPage/MyLearningPage";
import {NotFoundPage} from "./pages/NotFoundPage/NotFoundPage";
import {SandboxPage} from "./pages/Sandbox/sandboxPage";
import {RequireAuth} from "./routes/RequireAuth";
import {ManagerView} from "./pages/ContentManager/ContentManager";
import {NotesPage} from "./pages/NotesPage/NotesPage";

const Main: React.FC = () => {
	return (
		<main style={{height: "100%"}}>
			<BrowserRouter>
				<div className="App">
					<Routes>
						<Route path="/" element={<LoginPage/>} />
						<Route path="/register" element={<RegisterPage/>} />
						<Route path="/forgotten-password" element={<ForgottenPasswordPage/>} />
						<Route path="/reset-password" element={<ResetPasswordPage/>} />
						<Route path="/my-learning" element={<RequireAuth><MyLearningPage/></RequireAuth>} />
						<Route path="/notes" element={<RequireAuth><NotesPage/></RequireAuth>} />
						<Route path="/library" element={<RequireAuth><CourseLibraryPage/></RequireAuth>} />
						<Route path="/library/:courseSlug" element={<RequireAuth><CourseLibraryProductPage/></RequireAuth>} />
						<Route path="/course/:courseSlug/*" element={<RequireAuth><CoursePage/></RequireAuth>} />
						<Route path="/sandbox" element={<SandboxPage/>} />
						<Route path="/admin/*" element={<RequireAuth><ManagerView/></RequireAuth>} />
						<Route path="*" element={<NotFoundPage/>} />
					</Routes>
				</div>
			</BrowserRouter>
		</main>
	);
};

export default Main;