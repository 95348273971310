import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleTab} from "@plumeuk/shapeshift-common/module";
import {DynamicContainer, NotesSection, ModuleNotAvailable, ModuleTabs, Files, WYSIWYG, PageTitle, CommentsSection, ModuleCompleteButton, ModuleUnCompleteButton} from "@plumeuk/shapeshift-common/v2";
import {Links} from "@plumeuk/shapeshift-common/links";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
	},
	activeTab: {
	},
	extrasTitle: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},
	moduleContainer: {
		minHeight: `calc(100vh - ${theme.constants.toolbarHeight} - ${theme.constants.fullButtonHeight} - ${theme.constants.gutterSpacing})` // To do: find a nicer approach
	},
	goToNextLesson: {
		marginTop: theme.spacing(3),
		marginLeft: "auto",
		marginRight: "auto"
	},
	notAvailableContainer: {
		width: "100%",
		textAlign: "center",
		[theme.breakpoints.down("md")]: {
			paddingLeft: 0
		}
	}
}));

export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const [firstAvailableModule, setFirstAvailableModule] = useState<ICourseCurriculumModule | false>();
	const location = useLocation();

	useEffect(() => {
		if(courseSlug && lessonSlug && curriculum)
			setFirstAvailableModule(getModule(curriculum) ?? false)
	}, [courseSlug, lessonSlug, curriculum])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if(e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if(!e.isLoading && !e.isError && e.statusCode === 200){
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status})

			if(e?.data?.complete){
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	useEffect(() => {
		setTab("overview")
	}, [lessonSlug])

	const videoRef = useRef<VideoPlayerRef>(null);

	return (
		<>
			<LessonDataProvider courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum}>
				{({lesson, apiResult}) => <>
					{apiResult.statusCode === 200 && <>
						{lesson?.videoUrl && <>
							<LessonVideoPlayer ref={videoRef} lesson={lesson} currentTime={location?.state?.timestamp ?? undefined} />
						</>}
						<DynamicContainer className={classes.moduleContainer} size="constrained">
							<ModuleTabs moduleTabs={[{label: "Lesson", value: "overview"}, {label: "Discussions", value: "comments"}, {label: "Notes", value: "notes"}]} tabSelected={tabSelected} onChange={tab => setTab(tab)} />
							{tabSelected === "overview" && <>

								<PageTitle title={lesson?.title} subtitle={lesson?.subtitle} />
								<WYSIWYG>
									{lesson?.content ?? ""}
								</WYSIWYG>

								{lesson?.files && <>
									<Typography className={classes.extrasTitle} variant="h4">Files</Typography>
									<Files files={lesson.files}/>
								</>}

								{lesson?.links && lesson.links.length > 0 && <>
									<Typography className={classes.extrasTitle} variant="h4">Links</Typography>
									<Links links={lesson.links}/>
								</>}

							</>}
							{tabSelected === "comments" && <CommentsSection courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug}/>}
							{tabSelected === "notes" && <NotesSection courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} videoRef={videoRef} onGoToCourseAction={courseId => {navigate(`/course/${courseId}`)}} onGoToLessonAction={(courseId, moduleId, moduleType, timestamp) => {navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}})}} />}
						</DynamicContainer>

						{courseSlug && lesson?.slug && !lesson?.complete &&
							<ModuleCompleteButton
								variant="text"
								size="fullWidth"
								courseSlug={courseSlug}
								moduleSlug={lesson.slug}
								type="lesson"
								onApiUpdate={handleCompletion}
							/>
						}
						{courseSlug && lesson?.slug && lesson?.complete &&
							<ModuleUnCompleteButton
								variant="text"
								size="fullWidth"
								courseSlug={courseSlug}
								moduleSlug={lesson.slug}
								type="lesson"
								onApiUpdate={handleCompletion}
							/>
						}

					</>}
					{apiResult.statusCode === 403 && <DynamicContainer size="constrained" align="center" className={classes.notAvailableContainer} noGutter>
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
						{firstAvailableModule &&
						<Button
							size="large"
							variant="outlined"
							className={classes.goToNextLesson}
							onClick={() => navigate(`/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`)}
						>
							Go to next available lesson
						</Button>}
						{firstAvailableModule === false && <Button
							className={classes.goToNextLesson}
							onClick={() => navigate("/my-learning")}
						>
							Go back to My Learning
						</Button>}
					</DynamicContainer>}
				</>}
			</LessonDataProvider>
		</>
	);
}