import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Button, Box, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {ISurvey} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	surveyCompletion: {
		width: "100%",
		textAlign: "center"
	},
	summarySubtitle: {
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(5)
	},
	summaryStart: {
		marginRight: theme.spacing(1)
	},
	summaryEnd: {
		marginLeft: theme.spacing(1)
	}
}));

interface IProps {
	survey?: ISurvey
}

export const SurveyCompletion: FC<IProps> = ({survey}) => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const {courseSlug} = useParams();
	const surveyResponseSubmitted = (survey?.response?.length ?? 0) > 0;

	return (
		<Box className={classes.surveyCompletion}>
			<Typography variant="h2">
				{surveyResponseSubmitted ? "Survey response submitted!" : "Survey skipped"}
			</Typography>
			<Typography className={classes.summarySubtitle} variant="h5">
				{surveyResponseSubmitted
					? "If you would like to update your responses you can do so by clicking the button below, or you can proceed to the next module."
					: "No time, no problem! \nBut if you ever find yourself with a spare minute, we would love to know how you felt about the course."
				}
			</Typography>
			<Button size="large" className={classes.summaryStart} onClick={() => navigate("/course/" + courseSlug)}>
				Continue
			</Button>
			<Button size="large" className={classes.summaryEnd} variant="outlined" onClick={() => navigate("../active")}>
				{surveyResponseSubmitted ? "Update answers" : "Take survey"}
			</Button>
		</Box>
	);
}