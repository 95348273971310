import {Box} from "@mui/material";
import {LibraryCourseDataProvider, LibraryCoursesDataProvider, LibraryDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {salesBarWidth} from "../../constants";
import {PageBase} from "../../components/pageBase/pageBase";
import {DynamicContainer, VideoPlayer, ProductRecommended, ProductBanner, ProductSummaryTile, WYSIWYG} from "@plumeuk/shapeshift-common/v2";

const feather = "/PlumeFeather.svg"

const useStyles = makeStyles()((theme) => ({
	courseLibraryProductPage: {
	},
	productOuterContainer: {
		display: "flex",
		flexFlow: "wrap",
		justifyContent: "space-between",
		gap: theme.spacing(4)
	},
	productContainer: {
		width: `calc(95% - ${theme.constants.salesBarWidth} - ${theme.spacing(4)})`,
		maxWidth: `calc(${theme.containers.wide} - ${theme.constants.salesBarWidth} - ${theme.spacing(4)})`,
		[theme.breakpoints.down("lg")]: {
			width: `calc(95% - ${theme.constants.salesBarWidth} + (${salesBarWidth}/5) - ${theme.spacing(4)})`
		},
		[theme.breakpoints.down("md")]: {
			width: "100%"
		}
	},
	productSummaryTile: {
		width: salesBarWidth,
		position: "sticky",
		marginTop: theme.spacing(10),
		top: theme.spacing(4.5),
		alignSelf: "flex-start",
		[theme.breakpoints.down("lg")]: {
			width: `calc(${salesBarWidth} - (${salesBarWidth}/5))`
		},
		[theme.breakpoints.down("md")]: {
			position: "relative",
			top: 0,
			marginTop: 0,
			width: "100%"
		}
	},
	title:{
	},
	container: {
	},
	courseLibraryProductContent: {
		padding: theme.spacing(3),
		[theme.breakpoints.down("xl")]: {
			padding: theme.spacing(3, 0)
		}
	},
	tagContainer: {
	},
	videoPlayer: {
		marginTop: `-${theme.spacing(12)}`,
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(6),
		borderRadius: theme.borderRadius.medium,
		overflow: "hidden",
		[theme.breakpoints.down("xl")]: {
			marginLeft: 0,
			marginRight: 0
		}
	},
	productRecommended: {
		background: theme.palette.background.paper,
		padding: theme.spacing(7, 0, 0)
	}
}));

export const CourseLibraryProductPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const navigate = useNavigate();

	return (
		<PageBase disableSideBar>
			<LibraryCourseDataProvider slug={courseSlug}>
				{({course}) => {

					return(
						<Box className={classes.courseLibraryProductPage}>
							<DynamicContainer className={classes.productOuterContainer} size="wide">

								<Box className={classes.productContainer}>
									<ProductBanner
										course={course}
										bannerImage={feather}
										overTitle={course?.overTitle}
									/>
									{course?.videoUrl && <VideoPlayer
										className={classes.videoPlayer}
										url={course.videoUrl}
										controls={true}
									/>}
									<WYSIWYG className={classes.courseLibraryProductContent}>{course?.content ?? ""}</WYSIWYG>

								</Box>
								<ProductSummaryTile
									className={classes.productSummaryTile}
									infoBoxTitle={course?.infoBoxTitle}
									infoBoxSubtitle={course?.infoBoxSubtitle}
									infoBoxContent={course?.infoBoxContent}
									libraryItem={course ? {...course, __type: "plugin::strapi-plugin-shapeshift-cms.course"} : undefined}
									onEnrollAction={() => navigate("/my-learning")}
								/>
							</DynamicContainer>
							<LibraryDataProvider search={{searchTerm: ""}}>
								{({items}) => {
									const filteredCourses = items?.filter(e => course && e.id !== course.id) ?? [];
									return <ProductRecommended
										className={classes.productRecommended}
										onGoToItemAction={slug => navigate(`/library/${slug}`)}
										data={filteredCourses}
									/>
								}}
							</LibraryDataProvider>
						</Box>
					);
				}}
			</LibraryCourseDataProvider>
		</PageBase>
	);
}