import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {AdminSideMenuContent} from "./AdminSideMenuContent";
import {Route, Routes} from "react-router-dom";
import {AdminUsersPage} from "./Users/AdminUsersPage";
import {Box, ThemeProvider} from "@mui/material";
import {AdminCoursesPage} from "./Courses/AdminCoursesPage";
import {AdminLessonsPage} from "./Lessons/AdminLessonsPage";
import {AdminSettingsPage} from "./Settings/AdminSettingsPage";
import {AdminQuizzesPage} from "./Quizzes/AdminQuizzesPage";
import {adminTheme} from "../../templates/adminTheme";
// import {AdminReportsPage} from "./Pages/AdminReportsPage";
// import {AdminSupportPage} from "./Pages/AdminSupportPage";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
	},
	content: {
		padding: "20px 60px"
	}
}));

export const ManagerView: React.FC = () => {
	const {classes} = useStyles();

	return (
		<ThemeProvider theme={adminTheme}>
			<PageBase className={classes.pageBase} sideMenuWidthOverride={"225px"} sideBarContent={<AdminSideMenuContent />}>
				<Box className={classes.content}>
					<Routes>
						<Route index element={<AdminUsersPage />}/>
						<Route path="users/*" element={<AdminUsersPage />} />
						<Route path="courses/*" element={<AdminCoursesPage />} />
						<Route path="lessons/*" element={<AdminLessonsPage />} />
						<Route path="quizzes/*" element={<AdminQuizzesPage />} />
						{/*
						<Route path="reports" element={<AdminReportsPage />} />
						<Route path="support" element={<AdminSupportPage />} />*/}
						<Route path="settings" element={<AdminSettingsPage />} />
					</Routes>
				</Box>
			</PageBase>
		</ThemeProvider>
	);
}