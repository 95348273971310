import {Box, BoxProps} from "@mui/material";
import React, {FC, useEffect, useState, isValidElement, cloneElement, ReactNode, useContext} from "react";
import {NavBar, SideMenu} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";
import {PageBaseContext} from "../../contexts/pageBaseContext";

const logo = "/PlumeLogo.svg"

interface ICustomProps {
	children: React.ReactNode,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean,
	disableSideBarOpen?: boolean,
	sideMenuWidthOverride?: string
}

interface IStyleProps {
	sideMenuOpen: boolean,
	sideMenuWidthOverride?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, sideMenuWidthOverride}) => ({
	pageBase: {
		height: "100%"
	},
	sideMenu: {
		width: sideMenuWidthOverride || theme.constants.sideMenuWidth,
		backgroundColor: theme.palette.background.default,
		"& ::-webkit-scrollbar": {
			display: "none"
		}
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: theme.constants.toolbarHeight
	},
	contentContainer: {
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidthOverride || theme.constants.sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		overflowY: "scroll",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? (sideMenuWidthOverride || theme.constants.sideMenuWidth) : "0px",
		boxSizing: "border-box",
		top: theme.constants.toolbarHeight,
		height: `calc(100% - ${theme.constants.toolbarHeight})`,
		[theme.breakpoints.down("md")]: {
			width: "100%",
			left: 0
		}
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;

type pages = "My learning" | "Course library" | "Notes" | "Content manager";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"My learning": "/my-learning",
	"Course library": "/library",
	"Content manager": "/admin",
	"Notes": "/notes"
}

export const PageBase: FC<IProps> = ({sideBarContent, sideMenuWidthOverride, disableSideBar, children, className, ...props}) => {
	const mq = useMq();
	const location = useLocation();
	const [contextState, setContextState] = useContext(PageBaseContext)
	const sideMenuOpen = contextState.sidebarOpen;
	const {classes, cx} = useStyles({sideMenuOpen, sideMenuWidthOverride});
	const navigate = useNavigate();
	const [tabs, setTabs] = useState<pages[]>([]);
	const setSideMenuOpen = (e: boolean): void => setContextState(prev => ({...prev, sidebarOpen: e}))

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	useEffect(() => {
		if(contextState.sidebarEnabled === false)
			setSideMenuOpen(false)
	}, [contextState]);


	const handlePageSelect = (page: pages): void => {
		navigate(routesMap[page]);
	}

	const handleContentClick = (): void => {
		if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	useEffect(() => {
		const tabs: pages[] = ["My learning", "Course library", "Notes"];
		if(contextState.ownedCohort){
			tabs.push("Content manager");
		}

		setTabs(tabs);
	}, [contextState.ownedCohort])

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			<NavBar<pages>
				onBurgerAction={() => setContextState(prev => ({...prev, sidebarOpen: !prev.sidebarOpen}))}
				tabs={tabs}
				onPageSelect={handlePageSelect}
				pageSelected={pageSelected}
				logoPath={logo}
				disableSideBar={disableSideBar || !(contextState.sidebarEnabled)}
				editUserDetailsForm={EditUserDetailsForm}
			/>
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
			>
				{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
			</SideMenu>
			<Box className={classes.contentContainer} onClick={handleContentClick}>
				{children}
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
