import {Button, Box, Typography} from "@mui/material";
import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import Confetti from "react-confetti"
import {CourseCertificate, DynamicContainer} from "@plumeuk/shapeshift-common/v2";

const useStyles = makeStyles()((theme) => ({
	courseCertificatePage: {
		textAlign: "center"
	},
	courseCertificateContent: {
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(3)
	},
	dashBoardBtn: {
		margin: theme.spacing(1)
	},
	confetti: {
		width: "100vw",
		height: `calc(100vh - ${theme.toolbarHeight}px)`
	}
}));

export const CourseCertificatePage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const navigate = useNavigate();

	return (
		<DynamicContainer className={classes.courseCertificatePage} align="center" size="constrained">

			<Typography variant="h1">Congratulations!</Typography>
			<Typography variant="h5" className={classes.courseCertificateContent}>
				You finished the course and you have been awarded with a certificate. You can download it from the block below.
			</Typography>

			<CourseCertificate courseSlug={courseSlug} />

			<Box>
				<Button size="large" onClick={() => navigate("/my-learning")} className={classes.dashBoardBtn}>
					Go to dashboard
				</Button>
			</Box>

			<Confetti className={classes.confetti} />

		</DynamicContainer>
	);
}