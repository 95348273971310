import {Button, Box, Typography} from "@mui/material";
import {FC, useCallback, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import {WYSIWYG} from "@plumeuk/shapeshift-common/v2";
import {useNavigate, useParams} from "react-router-dom";
import {ISurvey} from "@plumeuk/shapeshift-types/ISurvey";
import {useSubmitSurveyResponse} from "@plumeuk/shapeshift-common/hooks";
import {CurriculumContext} from "../../../../contexts/curriculumContext";

const useStyles = makeStyles()((theme) => ({
	surveyStartSummary: {
		width: "100%",
		textAlign: "center"
	},
	summaryTitle: {
	},
	summarySubtitle: {
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(1)
	},
	summaryContent: {
		marginTop: theme.spacing(3)
	},
	summaryStart: {
		marginRight: theme.spacing(1)
	},
	summaryEnd: {
		marginLeft: theme.spacing(1)
	}
}));

interface IProps {
	survey?: ISurvey
}

export const SurveyStartSummary: FC<IProps> = ({survey}) => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: surveySlug} = useParams();
	const navigate = useNavigate();
	const {submitResponse, response} = useSubmitSurveyResponse();
	const {curriculumDispatch} = useContext(CurriculumContext);

	const handleStartAction = useCallback((): void => {
		navigate("active");
	}, [navigate]);

	const handleSkipAction = useCallback((): void => {
		if(!courseSlug || !surveySlug)
			return;

		submitResponse(courseSlug, surveySlug, [])
	}, [navigate, courseSlug, surveySlug]);

	useEffect(() => {
		if(!response || !surveySlug)
			return;

		curriculumDispatch({
			type: "setModuleComplete", module: {type: "survey", slug: surveySlug}, status: true})
	}, [response, surveySlug]);

	useEffect(() => {
		if(survey?.complete === true)
			navigate("complete");
	}, [survey])

	return (
		<Box className={classes.surveyStartSummary}>
			{survey?.title && (
				<Typography className={classes.summaryTitle} variant="h2">
					{survey.title}
				</Typography>
			)}
			{survey?.subtitle && (
				<Typography className={classes.summarySubtitle} variant="h5">
					{survey.subtitle}
				</Typography>
			)}
			<WYSIWYG className={classes.summaryContent}>
				{survey?.content ?? ""}
			</WYSIWYG>
			<Button size="large" className={classes.summaryStart} onClick={handleStartAction}>
				Continue
			</Button>
			<Button variant="outlined" size="large" className={classes.summaryEnd} onClick={handleSkipAction}>
				Skip
			</Button>
		</Box>
	)
}