import {LinearProgress} from "@mui/material";
import {SurveyQuestionListContainer as SSSurveyQuestionContainer} from "@plumeuk/shapeshift-common/v2";
import {ISurveyAnswer, ISurveyQuestion} from "@plumeuk/shapeshift-types/ISurvey";
import {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

interface IProps {
	questions?: ISurveyQuestion[],
	onSubmit: (answers: ISurveyAnswer[]) => void,
	response?: ISurveyAnswer[],
	submitResponseResultId?: string
}

export const SurveyQuestionContainer: FC<IProps> = ({questions, onSubmit, response, submitResponseResultId}) => {
	const navigate = useNavigate();
	const {courseSlug, moduleSlug: surveySlug} = useParams();

	useEffect(() => {
		if(submitResponseResultId)
			navigate("../complete/" + submitResponseResultId)
	}, [submitResponseResultId])


	if(!courseSlug || !surveySlug)
		return <LinearProgress variant="query"/>


	return (
		<SSSurveyQuestionContainer
			courseSlug={courseSlug}
			surveySlug={surveySlug}
			questions={questions}
			response={response}
			onSubmit={answers => {onSubmit(answers)}}
		/>
	);
}