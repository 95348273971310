import {useNavigate} from "react-router-dom";
import {CoursesBanner, CoursesTable, DynamicContainer} from "@plumeuk/shapeshift-common/v2";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";

const feather = "/PlumeFeather.svg"
const fallbackImage = "/fallbackImage.png"

const useStyles = makeStyles()((theme) => ({
	pageBase: {
	},
	dashboardContainer: {
		paddingTop: theme.spacing(5)
	}
}));

export const MyLearningPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} disableSideBar>
			<CoursesBanner
				bannerImage={feather}
			/>
			<DynamicContainer>
				<CoursesTable
					className={classes.dashboardContainer}
					fallbackImage={fallbackImage}
					onGoToCourseAction={courseId => {navigate("/course/" + courseId)}}
					onGoToCompletedCourseAction={courseId => {navigate(`/course/${courseId}/complete`)}}
				/>
			</DynamicContainer>
		</PageBase>
	);
}