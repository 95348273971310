import {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {ISurvey} from "@plumeuk/shapeshift-types/ISurvey";
import {SurveyStartSummary} from "./SurveyStartSummary/SurveyStartSummary";
import {SurveyQuestions} from "./SurveyQuestions/SurveyQuestions";
import {SurveyCompletion} from "./SurveyCompletion";

interface IProps {
	survey?: ISurvey
}

export const SurveyRouter: FC<IProps> = ({survey}) => (
	<Routes>
		<Route index element={<SurveyStartSummary survey={survey}/>} />
		<Route path="active" element={<SurveyQuestions />} />
		<Route path="complete" element={<SurveyCompletion survey={survey}/>} />
	</Routes>
)