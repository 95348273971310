import {useApi} from "@plumeuk/shapeshift-identity";
import {API} from "@plumeuk/shapeshift-identity";
import {createContext, ReactElement, Dispatch, FC, useState, useEffect} from "react";

const initialState: [PageBaseContextState, Dispatch<React.SetStateAction<PageBaseContextState>>] = [
	{sidebarEnabled: true, sidebarOpen: true},
	() => {}
];
const PageBaseContext = createContext(initialState);

type PageBaseContextState = {
	ownedCohort?: {title: string, id: number} | false,
	sidebarEnabled: boolean,
	sidebarOpen: boolean
}

interface IProps {
	children: ReactElement
}

const PageBaseProvider: FC<IProps> = ({children}) => {
	const local = localStorage.getItem("ownedCohort");
	const localParsedCohort = local ? JSON.parse(local) : false;
	const [state, setState] = useState<PageBaseContextState>({...initialState[0], ownedCohort: localParsedCohort});
	//to do: update to hook
	const [{data: ownedCohort, statusCode: ownedCohortStatusCode}] = useApi<false | {title: string, id: number}>(API.cohortIsOwner, localParsedCohort);

	useEffect(() => {
		if(ownedCohort && ownedCohortStatusCode === 200){
			setState(prev => ({...prev, ownedCohort}))
			localStorage.setItem("ownedCohort", JSON.stringify(ownedCohort))
		}
		else if(ownedCohortStatusCode === 200)
			localStorage.removeItem("ownedCohort");
	}, [ownedCohort, ownedCohortStatusCode])

	return (
		<PageBaseContext.Provider value={[state, setState]}>
			{children}
		</PageBaseContext.Provider>
	);
};

export {
	PageBaseContext,
	PageBaseProvider
};