import {FC} from "react";
import {PageBase} from "../../components/pageBase/pageBase";
import {CourseLibraryBanner} from "./CourseLibraryBanner";
import {useNavigate} from "react-router-dom";
import {Library} from "@plumeuk/shapeshift-common/v2";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2";

export const CourseLibraryPage: FC = () => {
	const navigate = useNavigate();

	return (
		<PageBase disableSideBar>
			<DynamicContainer size="wide">
				<CourseLibraryBanner />
				<Library
					enableProgressionBar={true}
					onGoToItemAction={slug => navigate(`/library/${slug}`)}
				/>
			</DynamicContainer>
		</PageBase>
	);
}