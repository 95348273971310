
import React, {useEffect, useRef, useState} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";

const configuration = {
	toolbar: [
		"heading",
		"|",
		"bold",
		"italic",
		"link",
		"bulletedList",
		"numberedList",
		"|",
		"indent",
		"outdent",
		"|",
		"blockQuote",
		"insertTable",
		"mediaEmbed",
		"undo",
		"redo"
	]
};

interface IProps {
	onChange: (e: { target: { name: string, value: string } }) => void,
	name?: string,
	value: string,
	disabled?: boolean,
	handles?: string[]
	className?: string
}

type pos = {x: number, y: number}

const useStyles = makeStyles<{menuVisible: boolean, menuPosition: pos}>()(
	(theme, {menuVisible, menuPosition}) => ({
		editor: {
			margin: "20px 0",
			"& .ck-editor__main": {
				"& > *": {
					background: theme.palette.grey[200]

				},
				"& strong": {
					fontWeight: 600
				},
				"& i": {
					fontStyle: "italic"
				},
				"& ol, ul": {
					paddingLeft: "20px"
				},
				"& h1": {
					fontSize: "24px",
					fontWeight: 600
				},
				"& h2": {
					fontSize: "20px",
					fontWeight: 600
				},
				"& h3": {
					fontSize: "16px",
					fontWeight: 600
				},
				"& p, h1, h2, h3, ul, ol": {
					margin: "15px 0"
				}
			}
		},
		handleMenu: {
			display: menuVisible ? "flex" : "none",
			flexDirection: "column",
			gap: "5px",
			position: "absolute",
			top: `${menuPosition.y}px`,
			left: `${menuPosition.x}px`,
			border: "1px solid #000",
			backgroundColor: "#fff",
			padding: "8px",
			textAlign: "left",
			zIndex: 50,
			"& button:hover":{
				opacity: 1
			},
			"& button":{
				opacity: 0.8,
				textAlign: "left"
			}
		}
	})
);


const Editor: React.FC<IProps> = ({onChange, name = "", value = "", disabled = false, className, handles}) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const [menuPosition, setMenuPosition] = useState<pos>({x: 0, y: 0});
	const editorRef = useRef<CKEditor<ClassicEditor>>(null);
	const {classes, cx} = useStyles({menuVisible, menuPosition});
	const handleMenuRef = useRef(null)

	const handleCloseMenu = (): void => {
		setMenuVisible(false);
	};

	const handleBlur = (): void => {
		setTimeout(() => handleCloseMenu(), 300);
	}

	const handleHandleSelection = (handle: string): void => {
		if(!editorRef.current?.editor)
			return;

		editorRef.current.editor.execute("input", {text: handle + "}"});
		editorRef.current.editor.focus();
		handleCloseMenu();
	};

	useEffect(() => {
		// Create a CKEditor instance
		if (!editorRef.current?.editor || !handles || handles.length === 0)
			return;

		const editor = editorRef.current.editor;

		editor.editing.view.document.on("keyup", (eventInfo, data) => {
			if (data.domEvent.key === "{") {
				const range = window?.getSelection()?.getRangeAt(0);
				const rect = range?.getBoundingClientRect();

				if (rect) {
					setMenuVisible(true);
					setMenuPosition({
						x: rect.left + window.scrollX,
						y: rect.bottom + window.scrollY
					});
				}
				eventInfo.stop(); // Prevent the default behavior of the "{" key
			}
			else if (data.domEvent.key !== "Shift")
				handleCloseMenu();
		});
	}, [handles]);

	return (
		<Box className={cx(classes.editor, className)}>
			<CKEditor
				ref={editorRef}
				editor={ClassicEditor}
				disabled={disabled}
				config={configuration}
				data={value || ""}
				onBlur={handleBlur}
				onReady={(editor) => editor.setData(value || "")}
				onChange={(_event, editor) => {
					editor.keystrokes
					const data = editor.getData();
					onChange({target: {name, value: data}});
				}}
			/>
			<div className={classes.handleMenu} ref={handleMenuRef}>
				{handles?.map((e, i) => <button onClick={() => handleHandleSelection(e)} key={i}>{e}</button>)}
				<button onClick={handleCloseMenu}>Close Menu</button>
			</div>
		</Box>
	);
};

export default Editor;
